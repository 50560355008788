"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import {
  CSSHighlight,
  CSSHighlightXLarge,
} from "../../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface IBlogCardItemCardCAtegory {
  $isSectionBgWhite?: Maybe<boolean> | undefined;
}

const BgWhiteBronze = css`
  color: var(--text-09);
  ${CSSHighlight};
`;

// const BgNotWhiteBronze = css`
//   color: var(--text-02);

//   ${CSSTitle02}
// `;

const BgWhiteDiamond = css`
  ${CSSHighlightXLarge}
`;

// const BgNotWhiteDiamond = css`
//   ${CSSTitle02Xlarge}
// `;

const Bronze = css<IBlogCardItemCardCAtegory>`
  ${BgWhiteBronze}
`;

const Silver = css<IBlogCardItemCardCAtegory>``;

const Gold = css<IBlogCardItemCardCAtegory>``;

const Platinum = css<IBlogCardItemCardCAtegory>``;

const Diamond = css<IBlogCardItemCardCAtegory>`
  ${BgWhiteDiamond}
`;

export const SBlogCardItemCardCategory = styled.p<IBlogCardItemCardCAtegory>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
