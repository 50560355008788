"use client";
import React from "react";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { BlogCardItem } from "./blog-card-item/BlogCardItem";
import { Button } from "../button/Button";
import { SCompBlogCardList } from "./styles/SCompBlogCardList";
import { SCompBlogCardListBlockHeader } from "./styles/SCompBlogCardListBlockHeader";
import { SCompBlogCardListButton } from "./styles/SCompBlogCardListButton";
import { SCompBlogCardListHeaderWrapper } from "./styles/SCompBlogCardListHeaderWrapper";
import { SCompBlogCardListSwiperMainContainer } from "./styles/SCompBlogCardListSwiperMainContainer";
import { ICompBlogCardList } from "./types";
import dynamic from "next/dynamic";

const CompSwiper = dynamic(() =>
  import("../comp-swiper/CompSwiper").then((mod) => mod.CompSwiper)
);

export const CompBlogCardListWithSwiper: React.FC<ICompBlogCardList> = ({
  button,
  cards,
  id,
  isSectionBgWhite,
  title,
}) => {
  return (
    <SCompBlogCardList
      id={id}
      withSwiper={true}
      $isSectionBgWhite={isSectionBgWhite}
    >
      <GridPadding>
        <SCompBlogCardListHeaderWrapper>
          {title && (
            <SCompBlogCardListBlockHeader withButton={!!button?.length}>
              {title}
            </SCompBlogCardListBlockHeader>
          )}
          {!!button?.length &&
            button.map(
              (btn) =>
                btn && (
                  <SCompBlogCardListButton key={`${btn.id}-${id}`}>
                    <Button {...btn}>{btn.title}</Button>
                  </SCompBlogCardListButton>
                )
            )}
        </SCompBlogCardListHeaderWrapper>
        {!!cards?.length && (
          <SCompBlogCardListSwiperMainContainer>
            <CompSwiper
              slidesPerView={1.1}
              spaceBetween={24}
              breakpoints={{
                1280: {
                  slidesPerView: cards.length > 2 ? 3 : 2,
                  spaceBetween: 24,
                },
              }}
              watchOverflow
              slides={cards.map(
                (card) =>
                  card && (
                    <BlogCardItem
                      key={`${card.id}-${id}`}
                      {...card}
                      isSectionBgWhite={isSectionBgWhite}
                      isSwiper={true}
                    />
                  )
              )}
            />
          </SCompBlogCardListSwiperMainContainer>
        )}
      </GridPadding>
    </SCompBlogCardList>
  );
};
