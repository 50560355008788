"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICompBlogCardList {
  $isSectionBgWhite?: Maybe<boolean> | undefined;
  id: any;
  withSwiper?: boolean;
}

const Bronze = css<ICompBlogCardList>`
  color: ${({ $isSectionBgWhite }) =>
    $isSectionBgWhite ? "var(--text-01)" : "var(--text-02)"};

  ${({ withSwiper }) => withSwiper && "overflow: hidden;"}
  .swiper-slide {
    height: auto;
  }
  .swiper {
    overflow: visible;
  }
`;

const Silver = css<ICompBlogCardList>``;

const Gold = css<ICompBlogCardList>``;

const Platinum = css<ICompBlogCardList>``;

const Diamond = css<ICompBlogCardList>``;

export const SCompBlogCardList = styled.div<ICompBlogCardList>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
