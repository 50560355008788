import { NextImage } from "src/components/next-image/NextImage";
import React, { Fragment } from "react";
import { getInternalLinkUrl } from "../../../utils/helpers/getInternalLinkUrl";
import { IGenBlogCard, Maybe } from "../../../utils/types_gen";
import { SBlogCardItem } from "./styles/SBlogCardItem";
import { SBlogCardItemCardCategory } from "./styles/SBlogCardItemCardCategory";
import { SBlogCardItemCardFooter } from "./styles/SBlogCardItemCardFooter";
import { SBlogCardItemCardTitle } from "./styles/SBlogCardItemCardTitle";
import { SBlogCardItemImageWrapper } from "./styles/SBlogCardItemImageWrapper";
import Link from "next/link";

interface IGenCustomBlockCard extends IGenBlogCard {
  isSwiper?: boolean | undefined | null;
  isSectionBgWhite?: Maybe<boolean> | undefined;
}

const BlogCardContainer = ({ children, link }: any) => {
  if (link?.url) {
    return (
      <a href={link.url} target={"_blank"}>
        {children}
      </a>
    );
  } else if (link?.internalLink?.[0]) {
    return (
      <Link href={`${getInternalLinkUrl(link.internalLink[0])}`}>
        <div>{children}</div>
      </Link>
    );
  }
  return <>{children}</>;
};

export const BlogCardItem: React.FC<IGenCustomBlockCard> = ({
  id,
  category,
  image,
  link,
  title,
  isSwiper,
  isSectionBgWhite,
}) => {
  return (
    <SBlogCardItem
      $id={id}
      $isSwiper={isSwiper}
      $isSectionBgWhite={isSectionBgWhite}
    >
      <BlogCardContainer link={link}>
        <>
          {image?.src && (
            <SBlogCardItemImageWrapper isSwiper={isSwiper}>
              <NextImage
                src={image.src}
                fill
                style={{ objectFit: "cover" }}
                alt={image?.description || image?.title || ""}
                loading="lazy"
              />
            </SBlogCardItemImageWrapper>
          )}
          {(title || category?.text) && (
            <SBlogCardItemCardFooter>
              {category && (
                <SBlogCardItemCardCategory $isSectionBgWhite={isSectionBgWhite}>
                  {category.text}
                </SBlogCardItemCardCategory>
              )}
              {title && (
                <SBlogCardItemCardTitle $isSectionBgWhite={isSectionBgWhite}>
                  {title}
                </SBlogCardItemCardTitle>
              )}
            </SBlogCardItemCardFooter>
          )}
        </>
      </BlogCardContainer>
    </SBlogCardItem>
  );
};
