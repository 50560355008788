"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";

interface ISBlogCardItemImageWrapper {
  isSwiper?: boolean | undefined | null;
}

const Bronze = css<ISBlogCardItemImageWrapper>`
  position: relative;
  padding-top: ${({ isSwiper }) => (isSwiper ? "81.35%" : "73.17%")};
`;

const Silver = css<ISBlogCardItemImageWrapper>``;

const Gold = css<ISBlogCardItemImageWrapper>`
  padding-top: 63.82%;
`;

const Platinum = css<ISBlogCardItemImageWrapper>``;

const Diamond = css<ISBlogCardItemImageWrapper>`
  padding-top: ${({ isSwiper }) => (isSwiper ? "58.63%" : "52.35%")};
`;

export const SBlogCardItemImageWrapper = styled.div<ISBlogCardItemImageWrapper>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
