"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import {
  CssBody02XLarge,
  CSSTitle02,
  CSSTitle02Xlarge,
} from "../../../../constants/styles/typographies/CSSTypographies";
import { Maybe } from "graphql/jsutils/Maybe";

interface IBlogCardItemTitle {
  $isSectionBgWhite?: Maybe<boolean> | undefined;
}

const BgWhiteBronze = css`
  color: var(--text-01);
  ${CSSTitle02}
`;

// const BgNotWhiteBronze = css`
//   color: var(--text-04);
//   ${CssBody02Med}
// `;

const Bronze = css<IBlogCardItemTitle>`
  ${BgWhiteBronze}
`;

const Silver = css<IBlogCardItemTitle>``;

const Gold = css<IBlogCardItemTitle>``;

const Platinum = css<IBlogCardItemTitle>``;

const Diamond = css<IBlogCardItemTitle>`
  /* ${({ $isSectionBgWhite }) =>
    $isSectionBgWhite
      ? `;
    `
      : `${CssBody02XLarge}
    font-weight:500;
      
      `} */

  ${CSSTitle02Xlarge}
`;

export const SBlogCardItemCardTitle = styled.h2<IBlogCardItemTitle>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
