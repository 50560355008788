"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading01,
  CSSHeading01Small,
  CSSHeading01XLarge,
  CSSHeading02,
  CSSHeading02Small,
  CSSHeading02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

interface ISCompBlogCardListBlockHeader {
  withButton?: boolean;
}

const Bronze = css<ISCompBlogCardListBlockHeader>`
  flex-grow: 1;
  ${({ withButton }) => (withButton ? CSSHeading02Small : CSSHeading01Small)}
`;

const Silver = css<ISCompBlogCardListBlockHeader>``;

const Gold = css<ISCompBlogCardListBlockHeader>`
  ${({ withButton }) => (withButton ? CSSHeading02 : CSSHeading01)}
`;

const Platinum = css<ISCompBlogCardListBlockHeader>``;

const Diamond = css<ISCompBlogCardListBlockHeader>`
  ${({ withButton }) => (withButton ? CSSHeading02XLarge : CSSHeading01XLarge)}
`;

export const SCompBlogCardListBlockHeader = styled.h2<ISCompBlogCardListBlockHeader>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
