"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/styles/mediaquerys";
import { Maybe } from "graphql/jsutils/Maybe";

interface IBlogCardItem {
  $isSwiper?: boolean | undefined | null;
  $isSectionBgWhite?: Maybe<boolean> | undefined;
  $id: any;
}

const Bronze = css<IBlogCardItem>`
  background: #fafafa;
  box-shadow: var(--big-elevation);
  cursor: pointer;
  height: ${({ $isSwiper }) => $isSwiper && "100%"};
  overflow: hidden;
  border-radius: 12px;
`;

const Silver = css<IBlogCardItem>``;

const Gold = css<IBlogCardItem>``;

const Platinum = css<IBlogCardItem>``;

const Diamond = css<IBlogCardItem>``;

export const SBlogCardItem = styled.div<IBlogCardItem>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
